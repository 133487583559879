<template>
	<div class="layout-footer">
		<div class="p-grid">
			<div class="p-col-6">
				<!-- <a href="/" class="logo-container">
					<img src="assets/layout/images/logo-black.png" alt="babylon-layout"/>
				</a> -->
			</div>
			<div class="p-col-6 footer-icons">
				<!-- <button class="p-link">
					<i class="pi pi-home"></i>
				</button>
				<button class="p-link">
					<i class="pi pi-globe"></i>
				</button>
				<button class="p-link">
					<i class="pi pi-envelope"></i>
				</button> -->
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "AppFooter"
	}
</script>

<style scoped>

</style>